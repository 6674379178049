import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/main"

const IndexPage = ({data}) => (
  <Layout page='home'>
    <SEO title="Home" image={data.imageSharp.fluid.src} />
    <Main data={data} />
  </Layout>
)

export default IndexPage

export const mainQuery = graphql`
 query HomePosts {
  featured: allContentfulSimpleLife(sort: {fields: date, order: DESC}, filter: {date: {gt: "2000-01-00T00:00+02:00"}, featured: {eq: true}}, limit: 4) {
    edges {
      node {
        comment
        date
        featured
        slug
        title
        shortDescription {
          json
        }
        content {
          json
        }
        cover {
          fluid(quality: 90) {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      next {
        slug
        title
      }
      previous {
        title
        slug
      }
    }
    totalCount
  }
  posts: allContentfulSimpleLife(sort: {fields: date, order: DESC}, filter: {date: {gt: "2000-01-00T00:00+02:00"}, featured: {eq: false}}, limit: 4) {
    edges {
      node {
        comment
        date
        featured
        slug
        title
        shortDescription {
          json
        }
        content {
          json
        }
        cover {
          fluid(quality: 90) {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      next {
        slug
        title
      }
      previous {
        title
        slug
      }
    }
    totalCount
  }
  imageSharp(fluid: {originalName: {eq: "alycante-logo-ellisse.png"}}) {
    id
    fluid {
      src
    }
  }
}

`
