import React from "react";
import {Link} from "gatsby"

import SidebarRecentPosts from "../components/sidebar-recent-posts";
import SidebarAboutMe from "../components/sidebar-about-me";
import Article from "../components/article"
import RichText from "./rich-text"

const Main = ({data}) => (
    <div className="container container-flex ">
      <main>
        <article className="article-featured ">
          <h2 className="article-title ">{data.featured.edges[0].node.title}</h2>
          <img
            src={data.featured.edges[0].node.cover.fluid.src}
            alt={data.featured.edges[0].node.coverAltText || 'no image description'}
            className="article-image flashing"
          />
          
          <RichText text={data.featured.edges[0].node.shortDescription.json} />

          <Link to={'/'+data.featured.edges[0].node.slug} className="article-read-more ">
            CONTINUA A LEGGERE
          </Link>
        </article>

        {data.posts.edges.map (({node}) => (
            <Article node={node} key={node.slug} />
           ))
        }

      </main>

      <aside className="sidebar">
        <SidebarAboutMe />
        <SidebarRecentPosts />
      </aside>
    </div>
  );

export default Main;
